import { useState } from "react";
import "./App.css";
import LoginButton from "./components/LoginButton";
import LogoutButton from "./components/LogoutButton";
import { LandingPage } from "./pages";
import LoadingPage from "./pages/LoadingPage";
import { useAuth0 } from "@auth0/auth0-react";
import LoginPage from "./pages/LoginPage";
import { Route, Routes } from "react-router-dom";
import ProfilePage from "./pages/ProfilePage";

function App() {
  const { isLoading, getIdTokenClaims, isAuthenticated } = useAuth0();
  const [authToken, setToken] = useState();

  if (isLoading) {
    return <LoadingPage></LoadingPage>;
  }

  if (isAuthenticated) {
    getIdTokenClaims().then((value) => {
      setToken(value["__raw"]);
    });
  } else {
    return <LoginPage></LoginPage>;
  }

  return (
    <div className="bg-gradient-custom h-screen w-screeno">
      <Routes>
        <Route
          exact
          path="/"
          element={<LandingPage authToken={authToken}></LandingPage>}
        ></Route>
        <Route
          path="/profile"
          element={<ProfilePage authToken={authToken}></ProfilePage>}
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
