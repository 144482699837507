import { useState } from "react";
import BottomPart from "../components/BottomPart";
import Navbar from "../components/Navbar";

const LandingPage = (props) => {
  const [searchStatus, isSearched] = useState(false);

  return (
    <div className="h-screen w-screen window-bg-img p-8 overflow-y-auto">
      <Navbar isSearched={isSearched} authToken={props.authToken}></Navbar>
      <BottomPart
        authToken={props.authToken}
        isSearched={isSearched}
        searchStatus={searchStatus}
      ></BottomPart>
    </div>
  );
};

export default LandingPage;
