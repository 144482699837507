import { useState, useRef, useEffect } from "react";
import LeakIcon from "../static/images/leak_icon.svg";
import SnippetIcon from "../static/images/snippet_icon.svg";
import PlayButton from "../static/images/play_button.svg";
import PauseBtn from "../static/images/pause_btn.svg";
import ReportButton from "./ReportButton";

const LeakCard = (props) => {
  const [rotatedCard, setRotateCard] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [uploadDate, setUploadDate] = useState(null);
  const [server, setServer] = useState(null);
  const [songName, setSongName] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(1);
  const audioRef = useRef(null);

  const dateTimeString = props.date;
  const dateTime = new Date(dateTimeString);
  const hours = dateTime.getHours() % 12 || 12;
  const minutes = String(dateTime.getMinutes()).padStart(2, "0");
  const ampm = dateTime.getHours() >= 12 ? "PM" : "AM";

  const formattedDateTime = `${new Date(props.date).getMonth() + 1}/${new Date(
    props.date
  ).getDate()}/${new Date(
    props.date
  ).getFullYear()} ${hours}:${minutes} ${ampm}`;

  // Formatting date
  const date2 = new Date(props.date);
  date2.setHours(0, 0, 0, 0); // Set the time component to midnight

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Set the time component to midnight

  const timeDiff = currentDate.getTime() - date2.getTime();
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  // Days ago
  let timeAgoStr = "";
  if (daysDiff === 0) {
    timeAgoStr = "today";
  } else if (daysDiff === 1) {
    timeAgoStr = "yesterday";
  } else if (daysDiff < 30) {
    timeAgoStr = `${daysDiff} days ago`;
  } else {
    const monthsDiff = Math.floor(daysDiff / 30);
    const remainingDays = daysDiff % 30;
    timeAgoStr = `${monthsDiff} ${monthsDiff > 1 ? "months" : "month"}${
      remainingDays > 0 ? ` and ${remainingDays} days` : ""
    } ago`;
  }

  function rotateCard(e) {
    if (
      !e.target.classList.contains("link-icon") &&
      !e.target.classList.contains("stop-flip") &&
      !e.target.classList.contains("music-player")
    ) {
      setRotateCard(!rotatedCard);

      if (!rotatedCard) {
        if (props.krakenFileHash !== "") {
          fetch(`https://krakenfiles.com/json/${props.krakenFileHash}`)
            .then((response) => {
              if (!response.ok) {
                console.log("error");
              }
              return response.json();
            })
            .then((data) => {
              setUploadDate(data.uploadDate);
              setServer(data.server);
              setSongName(data.title);
            });
        }
      }
    }
  }

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("loadedmetadata", () => {
        setDuration(audioRef.current.duration);
      });
    }
  }, [uploadDate]);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleVolumeChange = (e) => {
    audioRef.current.volume = e.target.value;
    setVolume(parseFloat(e.target.value));
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
    setDuration(audioRef.current.duration);
  };

  const handleProgressClick = (e) => {
    const progressTime =
      (e.nativeEvent.offsetX / e.target.offsetWidth) * duration;
    audioRef.current.currentTime = progressTime;
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
    return formattedTime;
  };
  return (
    <div
      onClick={(e) => {
        rotateCard(e);
      }}
      className="maincontainer mb-3"
    >
      <div className={`thecard ${rotatedCard === false ? "" : "rotated-card"}`}>
        <div className="thefront p-5">
          <div className="flex items-center">
            <div className="w-[25px] h-[25px] rounded-full leak-icon flex justify-center items-center">
              <img
                className="w-[22px] h-[22px]"
                loading="lazy"
                src={props.leakType === "LEAK" ? LeakIcon : SnippetIcon}
                alt="Info"
              />
            </div>
            <h6 className="ml-4">
              {props.leakType === "LEAK" ? "Leak" : "Snippet"} -{" "}
              {props.subheading}
            </h6>
            <div className="ml-auto text-sm flex items-center">
              <span data-tooltip="Leaked" className="tooltip">
                {props.link
                    ? <a
                        target="_blank"
                        href={props.link}
                        className="fa-solid fa-link link-icon"
                    ></a>
                    : null
                }
              </span>
              <span data-tooltip="Krakenfiles" className="tooltip">
                {props.metadata?.link
                    ? <a
                        href={props.metadata.link}
                        target="_blank"
                        className="fa-regular fa-file link-icon"
                    ></a>
                    : null
                }
              </span>
              <span data-tooltip="Report" className="tooltip">
                <ReportButton
                  id={props.id}
                />
              </span>
            </div>
          </div>
          <div className="text-sm leak-bottom-part">
            <p className="mb-2 font-normal">{props.heading}</p>
            <div className="flex items-center">
              <p className="font-normal">{formattedDateTime}</p>
              <p className="time-ago ml-auto">{timeAgoStr}</p>
            </div>
          </div>
        </div>

        <div className="theback p-2">
          {uploadDate ? (
            <div className="music-player-wrapper p-2">
              <p className="font-thin">{songName}</p>
              <div>
                <div>
                  <progress
                    max={duration}
                    value={currentTime}
                    onClick={handleProgressClick}
                    className="music-player"
                  />
                </div>
                <audio
                  src={`https://${server}krakenfiles.com/uploads/${uploadDate}/${props.krakenFileHash}/music.m4a`}
                  ref={audioRef}
                  onTimeUpdate={handleTimeUpdate}
                  onEnded={() => setIsPlaying(false)}
                  preload="metadata"
                  id="audioPlayer"
                />
              </div>
              <div className="music-player-bottom">
                <p>{`${formatTime(currentTime)} / ${formatTime(duration)}`}</p>
                <button className="music-player" onClick={togglePlay}>
                  {isPlaying ? (
                    <img className="music-player" src={PauseBtn}></img>
                  ) : (
                    <img className="music-player" src={PlayButton}></img>
                  )}
                </button>
                <input
                  type="range"
                  min="0.0"
                  max="1.0"
                  step="0.01"
                  value={volume}
                  onChange={handleVolumeChange}
                  className="music-volume music-player"
                />
              </div>
            </div>
          ) : (
            <p className="text-white text-center mt-10 w-full no-data-msg">
              Preview Unavailable <span className="text-red-500">*</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeakCard;
