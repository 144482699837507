export const setSearch = (search) => {
  return {
    type: "SETSEARCH",
    payload: search,
  };
};

export const setOffset = (offset) => {
  return {
    type: "SETOFFSET",
    payload: offset,
  };
};

export const setNotifications = (notification) => {
  return {
    type: "SETNOTIFICATION",
    payload: notification,
  };
};
export const setArtist = (artist) => {
  return {
    type: "SETARTIST",
    payload: artist,
  };
};
