import { useState } from "react";
import { setArtist, setNotifications } from "../actions";
import { useDispatch } from "react-redux";

const ArtistCard = (props) => {
  const dispatch = useDispatch();
  const [isSelected, toggleSelection] = useState(false);

  const dateTimeString = props.metaData.last_leak;
  const dateTime = new Date(dateTimeString);
  const formattedTime = dateTime.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  function showArtist() {
    dispatch(setNotifications(true));
    dispatch(setArtist(props.artistName));
  }
  return (
    <div
      onClick={!props.isExporting ? showArtist : null}
      disabled={props.isExporting}
      className="w-1/3 h-[150px] relative mb-3 artist-card cursor-pointer"
    >
      <div className="w-9/12 rounded-3xl p-1 absolute inset-0 bg-black opacity-80 card-bg mx-auto hover:opacity-40 duration-200"></div>
      <img
        loading="lazy"
        className="object-cover w-9/12 h-[150px] mx-auto rounded-3xl"
        src={props.artistImage}
        alt=""
      />
      {!props.isExporting ? (
        <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-medium text-center pointer-events-none artist-data">
          {props.artistName} <br />
          <span className="font-light text-sm block">
            Number of Leaks: {props.metaData.leaks}
          </span>
          <span className="font-light text-sm block">
            Number of Snippets: {props.metaData.snippets}
          </span>
          {props.metaData.leaks !== 0 && !props.metaData.error ? (
            <span className="font-light text-sm block">
              Last Leak:{" "}
              {props.metaData.last_leak &&
              props.metaData.last_leak.split("T")[0]
                ? props.metaData.last_leak.split("T")[0]
                : props.metaData.last_leak}{" "}
              {formattedTime}
            </span>
          ) : (
            ""
          )}
        </p>
      ) : (
        <div
          onClick={() => {
            toggleSelection(!isSelected);
          }}
          className="artist-checkbox"
        >
          {!isSelected ? (
            <p>Select</p>
          ) : (
            <i
              data-checked={props.artistName}
              className="fa-solid fa-check artist-checked"
            ></i>
          )}
        </div>
      )}
    </div>
  );
};

export default ArtistCard;
