import React, { useEffect, useState } from "react";
import Notification from "./Notification";
import { useDispatch, useSelector } from "react-redux";
import { setNotifications } from "../actions";

const NotificationsSection = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(undefined);
  const [isFinished, setFinished] = useState(false);
  let [offset, setOffset] = useState(0);
  let [label, setLabel] = useState("leak");
  let [leaksNum, setLeaksNum] = useState("...");
  let [snippetsNum, setSnippetsNum] = useState("...");
  let [trigger, setTrigger] = useState(0);
  const limit = 10;
  const artist = useSelector((state) => state.artist);

  useEffect(() => {
    fetch(
      process.env.REACT_APP_API_URL +
        `/posts?limit=${limit}&offset=${offset}&label=${label}&artist=${artist}`,
      {
        headers: new Headers({
          Authorization: "Bearer " + props.authToken,
        }),
      }
    )
      .then((response) => response.json())
      .then((fetchedData) => {
        document.querySelector(".loader3").style.display = "block";
        document.querySelector(".loader").style.display = "none";
        setLeaksNum(fetchedData.count);
        if (fetchedData.data !== undefined || fetchedData.data !== null) {
          if (data === undefined) {
            setData(fetchedData);
            setFinished(false);
          } else {
            setData({
              count: fetchedData.count,
              data: [...data.data, ...fetchedData.data],
            });
            if (fetchedData.count - data.data.length <= limit) {
              setFinished(true);
              document.querySelector(".loader3").style.display = "none";
            }
          }
          if (fetchedData.count < 11 && fetchedData.data !== null) {
            setFinished(true);
            document.querySelector(".loader3").style.display = "none";
          }
        }
        if (fetchedData.data === null) {
          setFinished(true);
          document.querySelector(".loader3").style.display = "none";
        }
      });
  }, [props.authToken, offset, trigger]);

  useEffect(() => {
    fetch(
      process.env.REACT_APP_API_URL +
        `/posts?limit=${limit}&offset=${offset}&label=snippet&artist=${artist}`,
      {
        headers: new Headers({
          Authorization: "Bearer " + props.authToken,
        }),
      }
    )
      .then((response) => response.json())
      .then((fetchedData) => {
        setSnippetsNum(fetchedData.count);
      });
  }, [props.authToken]);

  function checkIfScrollEnd(e) {
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
      if (isFinished !== true) {
        setOffset(limit + offset);
      }
    }
  }

  function switchNotification(label, trigger, e) {
    setLabel(label);
    setData(undefined);
    setOffset(0);
    setTrigger(trigger);
    document.querySelector(".loader3").style.display = "none";
    document.querySelector(".loader").style.display = "block";
    document.querySelectorAll(".notification-link").forEach((link) => {
      link.classList.remove("active-notification-link");
    });
    e.target.classList.add("active-notification-link");
    if (e.target.tagName === "SPAN") {
      e.target.parentElement.classList.add("active-notification-link");
    }
  }

  return (
    <div className="h-[898px] notifications-section">
      {" "}
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="navbar z-20 relative notifications-navbar">
        <a
          onClick={() => {
            dispatch(setNotifications(false));
          }}
          href="#"
          className="fa-solid fa-arrow-left notifications-arrow"
          style={{ color: "#ffffff" }}
        ></a>
        <div
          onClick={(e) => {
            switchNotification("leak", 0, e);
          }}
          className="nav-link notification-link active-notification-link"
        >
          <span>Leaks</span>
          <span className="notifications-num">{leaksNum}</span>
        </div>
        <div
          onClick={(e) => {
            switchNotification("snippet", 1, e);
          }}
          className="nav-link notification-link"
        >
          <span>Snippets</span>
          <span className="notifications-num">{snippetsNum}</span>
        </div>
      </div>
      <div
        onScroll={(e) => {
          checkIfScrollEnd(e);
        }}
        className="p-4 z-20 relative h-[830px] overflow-y-auto notifications-container"
      >
        {data !== undefined ? (
          data.data !== null ? (
            data.data.map((d, i) => {
              return (
                <Notification
                  krakenFileHash={
                    d.link && d.link.includes("/file.html")
                      ? d.link.split("view/")[1].split("/file.html")[0]
                      : ""
                  }
                  data={d}
                  key={i}
                  label={label}
                ></Notification>
              );
            })
          ) : (
            <p className="text-white text-center mt-6 w-full no-data-msg">
              No data <span className="text-red-500">*</span>
            </p>
          )
        ) : (
          ""
        )}
        <div className="w-full">
          <div className="loader3 w-3/3"></div>
        </div>
      </div>
      <span className="loader"></span>
    </div>
  );
};

export default NotificationsSection;
