import React from "react";
import Logo from "../static/images/main_logo.svg";
import LoginButton from "../components/LoginButton";

const LoginPage = () => {
  return (
    <div className="bg-gradient-custom h-screen w-screeno relative">
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <img className="w-[150px] mb-8" src={Logo} alt="" />
        <LoginButton></LoginButton>
      </div>
    </div>
  );
};

export default LoginPage;
