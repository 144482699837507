import { useEffect, useState } from "react";
import LeakCard from "./LeakCard";
import { useSelector } from "react-redux";

const BottomRight = (props) => {
  const [data, setData] = useState(undefined);
  const [offset, setOffset] = useState(0);
  const [isFinished, setFinished] = useState(false);
  const limit = 20;
  const searchValue = useSelector((state) => state.search);

  useEffect(() => {
    if (props.authToken === undefined) {
      return;
    }

    fetch(
      process.env.REACT_APP_API_URL +
        `/posts?limit=${limit}&offset=${offset}&search=${searchValue}`,
      {
        headers: new Headers({
          Authorization: "Bearer " + props.authToken,
        }),
      }
    )
      .then((response) => response.json())
      .then((fetchedData) => {
        if (fetchedData.data !== undefined && fetchedData.data !== null) {
          if (data === undefined || props.searchStatus === true) {
            setData(fetchedData);
            props.isSearched(false);
            setFinished(false);
            document.querySelector(".loader2").style.display = "block";
          } else {
            setData({
              count: fetchedData.count,
              data: [...data.data, ...fetchedData.data],
            });
            if (fetchedData.count - data.data.length <= limit) {
              setFinished(true);
              document.querySelector(".loader2").style.display = "none";
            }
          }
        }
        if (fetchedData.count < 21) {
          document.querySelector(".loader2").style.display = "none";
          setFinished(true);
        }
      });
  }, [props.authToken, offset, searchValue]);

  function checkIfScrollEnd(e) {
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
      if (isFinished !== true) {
        setOffset(limit + offset);
        document.querySelector(".loader2").style.display = "block";
      }
    }
  }

  return (
    <div
      onScroll={(e) => {
        checkIfScrollEnd(e);
      }}
      className="w-2/6 h-[915px] ml-10 right-bg rounded-xl p-4 overflow-y-auto scrollbar landing-right-part"
    >
      <div className="flex mb-7">
        <h5 className="text-white mb-3 relative z-10 text-lg">Recent leaks</h5>
        {data === null ? (
          ""
        ) : (
          <p className="ml-2 text-xs text-white opacity-50">
            {data !== undefined ? data.count : ""}
          </p>
        )}
        <a
          className="fa-solid fa-angle-down text-white navsearch-bg p-2 h-full items-center flex justify-center rounded-full ml-auto"
          href="#"
        ></a>
      </div>
      {data === undefined || props.searchStatus === true ? (
        <span className="loader"></span>
      ) : !data.data ? (
        <p className="text-white text-center mt-6 w-full no-data-msg">
          No data <span className="text-red-500">*</span>
        </p>
      ) : (
        data.data &&
        data.data.length > 0 &&
        data.data.map((d, i) => {
          return (
            <LeakCard
              key={i}
              id={d.id}
              heading={d.title}
              subheading={d.artist}
              link={d.thread}
              date={d.created_at}
              dayAgo="1 day ago"
              leakType={d.label}
              krakenFileHash={
                d.link && d.link.includes("/file.html")
                  ? d.link.split("view/")[1].split("/file.html")[0]
                  : ""
              }
              metadata={d.metadata}
            ></LeakCard>
          );
        })
      )}
      <div className="loader2"></div>
    </div>
  );
};

export default BottomRight;
