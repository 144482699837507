import { useDispatch } from "react-redux";
import { setSearch } from "../actions";
import { setOffset } from "../actions";

const NavSearchBar = (props) => {
  const dispatch = useDispatch();

  function search(e) {
    if (e.keyCode === 13) {
      dispatch(setOffset(0));
      dispatch(setSearch(e.target.value));
      props.isSearched(true);
      document.querySelector(".loader3").style.display = "none";
      document.querySelector(".loader2").style.display = "none";
    }
  }
  const handleBlur = (e) => {
    if (e.target.value !== "") {
      document.querySelector(".navbar-input-placeholder").style = "opacity: 0";
    } else {
      document.querySelector(".navbar-input-placeholder").style = "opacity: 1";
    }
  };
  const handleFocus = (e) => {
    document.querySelector(".navbar-input-placeholder").style = "opacity: 0";
  };

  function removeFocus(e) {
    if (e.target.value.length === 0) {
      document.querySelector(".navbar-input-placeholder").style = "opacity: 1";
      e.target.blur();

      dispatch(setSearch(e.target.value));
      dispatch(setOffset(0));
      props.isSearched(true);
      document.querySelector(".loader3").style.display = "none";
      document.querySelector(".loader2").style.display = "none";
    }
  }

  return (
    <div
      className={
        window.location.pathname === "/profile"
          ? "w-full relative opacity-20 pointer-events-none"
          : "w-full relative"
      }
    >
      <input
        onKeyDown={(e) => search(e)}
        onBlur={(e) => handleBlur(e)}
        onFocus={handleFocus}
        onInput={(e) => {
          removeFocus(e);
        }}
        className="navsearch-bg h-full w-full flex align-middle justify-center items-center rounded-lg outline-none text-center navbar-input text-white text-xs"
        type="text"
      />
      <div className="absolute pointer-events-none flex text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 navbar-input-placeholder">
        <i className="fa-solid fa-magnifying-glass text-xs mr-1"></i>
        <p className="m-0 text-xs">What are you looking for?</p>
      </div>
    </div>
  );
};

export default NavSearchBar;
