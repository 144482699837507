import setSearch from "./setSearch";
import setOffset from "./setOffset";
import setNotifications from "./setNotifications";
import setArtist from "./setArtist";
import { combineReducers } from "redux";

const allReducers = combineReducers({
  search: setSearch,
  offset: setOffset,
  notifications: setNotifications,
  artist: setArtist,
});

export default allReducers;
