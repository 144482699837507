import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <button
      className="flex-1 p-2 rounded-xl menu-icon duration-200 text-left"
      onClick={() => logout()}
    >
      Logout
    </button>
  );
};

export default LogoutButton;
