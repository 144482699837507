import React, { useEffect, useRef, useState } from "react";
import AudioWaves from "../static/images/audio_waves_icon.svg";
import PlayButton from "../static/images/play_button.svg";
import PauseBtn from "../static/images/pause_btn.svg";

const Notification = (props) => {
  const [audioPlayer, setAudioPlayer] = useState(false);
  const [borderRadius, setBorderRadius] = useState(12);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const date2 = new Date(props.data.created_at);
  const [volume, setVolume] = useState(1);
  const [uploadDate, setUploadDate] = useState(null);
  const [server, setServer] = useState(null);
  const [songName, setSongName] = useState(null);
  const audioRef = useRef(null);
  date2.setHours(0, 0, 0, 0); // Set the time component to midnight

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Set the time component to midnight
  const timeDiff = currentDate.getTime() - date2.getTime();
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  // Days ago
  let timeAgoStr = "";
  if (daysDiff === 0) {
    timeAgoStr = "today";
  } else if (daysDiff === 1) {
    timeAgoStr = "yesterday";
  } else if (daysDiff < 30) {
    timeAgoStr = `${daysDiff} days ago`;
  } else {
    const monthsDiff = Math.floor(daysDiff / 30);
    const remainingDays = daysDiff % 30;
    timeAgoStr = `${monthsDiff} ${monthsDiff > 1 ? "months" : "month"}${
      remainingDays > 0 ? ` and ${remainingDays} days` : ""
    } ago`;
  }

  const dateTimeString = props.data.created_at;
  const dateTime = new Date(dateTimeString);
  const hours = dateTime.getHours() % 12 || 12;
  const minutes = String(dateTime.getMinutes()).padStart(2, "0");
  const ampm = dateTime.getHours() >= 12 ? "PM" : "AM";

  const formattedDateTime = `${
    new Date(props.data.created_at).getMonth() + 1
  }/${new Date(props.data.created_at).getDate()}/${new Date(
    props.data.created_at
  ).getFullYear()} ${hours}:${minutes} ${ampm}`;

  useEffect(() => {
    if (audioPlayer) {
      setBorderRadius(0);
    } else {
      setTimeout(() => {
        setBorderRadius(12);
      }, 300);
    }
  }, [audioPlayer]);

  function showAudioPlayer() {
    setAudioPlayer(!audioPlayer);

    if (props.krakenFileHash !== "") {
      fetch(`https://krakenfiles.com/json/${props.krakenFileHash}`)
        .then((response) => {
          if (!response.ok) {
            console.log("error");
          }
          return response.json();
        })
        .then((data) => {
          setUploadDate(data.uploadDate);
          setServer(data.server);
          setSongName(data.title);
        });
    }
  }

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("loadedmetadata", () => {
        setDuration(audioRef.current.duration);
      });
    }
  }, [uploadDate]);

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
    setDuration(audioRef.current.duration);
  };

  const handleProgressClick = (e) => {
    const progressTime =
      (e.nativeEvent.offsetX / e.target.offsetWidth) * duration;
    audioRef.current.currentTime = progressTime;
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
    return formattedTime;
  };

  const handleVolumeChange = (e) => {
    audioRef.current.volume = e.target.value;
    setVolume(parseFloat(e.target.value));
  };
  return (
    <div className="w-full block rounded-sm mb-3 notification">
      <div className="flex">
        <div
          style={{ borderBottomLeftRadius: borderRadius }}
          className="notification-first w-1/12"
        >
          <div>
            <span>{props.data.author.slice(0, 2).toUpperCase()}</span>
          </div>
        </div>
        <div
          style={{ borderBottomRightRadius: borderRadius }}
          className="notification-second w-11/12 p-4 text-white flex"
        >
          <div className="w-11/12">
            <p className="font-medium notification-author mb-2">
              {props.data.author}
            </p>
            <a
              target="_blank"
              href={props.data.thread}
              className="fa-solid fa-link link-icon ml-2 cursor-pointer"
            ></a>
            {props.data.metadata ? (
              <a
                href={props.data.metadata.link}
                target="_blank"
                className="fa-regular fa-file link-icon"
              ></a>
            ) : (
              ""
            )}
            {props.krakenFileHash ? (
              <img
                onClick={() => showAudioPlayer()}
                className="inline-block ml-2 w-[15px] audio-icon"
                src={AudioWaves}
                alt="Audio waves"
              />
            ) : (
              ""
            )}

            <p className="font-medium mb-3 opacity-70">{props.data.title}</p>
            <p className="text-sm flex">{formattedDateTime} </p>
          </div>
          <div className="w-1/12 relative">
            <div className="flex items-center">
              <span className="text-xs light-gray mr-2 text-center">
                {timeAgoStr}
              </span>
              <i className="fa-regular fa-clock light-gray"></i>
            </div>
          </div>
        </div>
      </div>
      {uploadDate ? (
        <div
          className={`bg-black overflow-hidden leak-playback flex ${
            audioPlayer ? "open" : ""
          }`}
        >
          <div className="w-1/12 leak-audio-first flex h-full">
            <button className="music-player" onClick={togglePlay}>
              {isPlaying ? (
                <img className="music-player" src={PauseBtn}></img>
              ) : (
                <img className="music-player" src={PlayButton}></img>
              )}
            </button>
          </div>
          <div className="w-11/12 leak-audio-scnd flex justify-evenly">
            <progress
              max={duration}
              value={currentTime}
              onClick={handleProgressClick}
              className="leak-music-player cursor-pointer"
            />
            <audio
              src={`https://${server}krakenfiles.com/uploads/${uploadDate}/${props.krakenFileHash}/music.m4a`}
              ref={audioRef}
              onTimeUpdate={handleTimeUpdate}
              onEnded={() => setIsPlaying(false)}
              preload="metadata"
              id="audioPlayer"
            />
            <p className="text-white">{`${formatTime(
              currentTime
            )} / ${formatTime(duration)}`}</p>
            <input
              type="range"
              min="0.0"
              max="1.0"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
              className="music-volume music-player"
            />
            <p className="text-white">{songName}</p>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Notification;
