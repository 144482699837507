import MainLogo from "../static/images/main_logo.svg";
import MenuIcon from "../static/images/menu_icon.svg";
import NavIcon from "../static/images/nav_icon.svg";
import FolderIcon from "../static/images/folder_icon.svg";
import EmailIcon from "../static/images/email_icon.png";
import CloseDropdown from "../static/images/close_dropdown.svg";
import NavSearchBar from "./NavSearchBar";
import LogoutButton from "./LogoutButton";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setNotifications } from "../actions";
import { useEffect, useState } from "react";

const Navbar = (props) => {
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const limit = 18;
  let [offset, setOffset] = useState(0);
  let [notificationsNum, setNotificationsNum] = useState("");
  const [artistsData, setArtistsData] = useState(undefined);
  const notifications = useSelector((state) => state.notifications);
  const [isFinished, setFinished] = useState(false);
  const fileWebsite = process.env.REACT_APP_FILES_WEBSITE;

  function toggleDropdown(dropdownType) {
    const dropdown = document.querySelector(dropdownType);
    const maxHeight = dropdown.style.maxHeight;

    if (maxHeight === "0px") {
      dropdown.style.maxHeight = "610px";
      if (dropdownType === ".main-dropdown-scnd") {
        document.querySelector(".main-dropdown-arrow").style =
          "transform: rotate(180deg)";
      }
    } else {
      dropdown.style.maxHeight = "0px";
      if (dropdownType === ".main-dropdown-scnd") {
        document.querySelector(".main-dropdown-arrow").style =
          "transform: rotate(0deg)";
      }
    }
    if (dropdownType === ".main-dropdown") {
      if (
        !document
          .querySelector(".nav-main-dropdown")
          .classList.contains("toggle-overflow")
      ) {
        document
          .querySelector(".nav-main-dropdown")
          .classList.toggle("toggle-overflow");
      } else {
        setTimeout((e) => {
          document
            .querySelector(".nav-main-dropdown")
            .classList.toggle("toggle-overflow");
        }, 500);
      }
    }
  }

  function toggleScndDropdown(heading, section, dropdownType, arrow) {
    const dropdown = document.querySelector(dropdownType);
    const maxHeight = dropdown.style.maxHeight;

    if (maxHeight === "0px") {
      dropdown.style.maxHeight = "500px";
      document.querySelector(section).style = "background-color: #761819";
      document.querySelector(heading).style = "background-color: #AA2324";
      document.querySelector(arrow).style = "transform: rotate(180deg)";
    } else {
      dropdown.style.maxHeight = "0px";
      document.querySelector(section).style = "background-color: #761819";
      document.querySelector(heading).style = "background-color: #282828";
      document.querySelector(arrow).style = "transform: rotate(0deg)";
    }
  }

  useEffect(() => {
    if (props.authToken === undefined) {
      return;
    }
    fetch(
      process.env.REACT_APP_API_URL +
        `/artists?limit=${limit}&offset=${offset}&search=`,
      {
        headers: new Headers({
          Authorization: "Bearer " + props.authToken,
        }),
      }
    )
      .then((response) => response.json())
      .then((fetchedData) => {
        if (fetchedData.data !== undefined || fetchedData.data !== null) {
          if (artistsData === undefined) {
            setArtistsData(fetchedData);
            setFinished(false);
          } else {
            setArtistsData({
              count: fetchedData.count,
              data: [...artistsData.data, ...fetchedData.data],
            });
            if (fetchedData.count - artistsData.data.length <= limit) {
              setFinished(true);
              document.querySelector(".loader4").style.display = "none";
            }
          }
          if (fetchedData.count < 19 && fetchedData.data !== null) {
            document.querySelector(".loader4").style.display = "none";
          }
        }
      });
  }, [props.authToken, offset]);

  function checkIfScrollEnd(e) {
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
      if (isFinished !== true) {
        setOffset(limit + offset);
      }
    }
  }

  useEffect(() => {
    if (props.authToken === undefined) {
      return;
    }
    fetch(
      process.env.REACT_APP_API_URL + `/user/notifications?limit=7&offset=0`,
      {
        headers: new Headers({
          Authorization: "Bearer " + props.authToken,
        }),
      }
    )
      .then((response) => response.json())
      .then((fetchedData) => {
        setNotificationsNum(fetchedData.count);
      });
  }, [props.authToken]);

  // Show additional dropdown
  function showDropdown() {
    timingTimeout = setTimeout(() => {
      document.querySelector(".artist-aditional-dropdown").style =
        "visibility: visible; opacity: 1";
    }, 200);
  }
  let timingTimeout;
  function showAditionalDropdown() {
    clearTimeout(timingTimeout);
  }
  function hideDropdown() {
    document.querySelector(".artist-aditional-dropdown").classList.add("open");

    timingTimeout = setTimeout(() => {
      document.querySelector(".artist-aditional-dropdown").style =
        "visibility: hidden; opacity: 0";
    }, 200);
  }
  function hideAditionalDropdown() {
    timingTimeout = setTimeout(() => {
      document.querySelector(".artist-aditional-dropdown").style =
        "visibility: hidden; opacity: 0";
    }, 200);
  }

  return (
    <div className="w-full flex">
      <div className="left-part nav-bg rounded-lg p-2 w-5/6 flex relative">
        <Link
          to="/"
          onClick={() => {
            if (notifications) window.location.reload();
          }}
        >
          <img className="w-12" src={MainLogo} alt="Logo" />
        </Link>

        <div className="flex align-middle w-1/5 justify-evenly ml-10 mr-10">
          <div className="w-[32px] h-[32px] mt-0.5">
            <img
              className="w-full h-full cursor-pointer nav-icons-hover rounded-lg duration-300 p-2 menu-icon"
              src={MenuIcon}
              alt="Menu"
              onClick={() => {
                toggleDropdown(".main-dropdown");
              }}
            />
            <div
              className="absolute top-[53px] left-0 m-auto dropdown-bg w-[283px] z-20 rounded-b-lg main-dropdown transition-max-height nav-main-dropdown toggle-overflow"
              style={{
                maxHeight: 0,
                transition: "max-height 0.3s ease-in-out",
              }}
            >
              <div className="flex mb-5">
                <img
                  className="ml-auto mt-3 mr-3 cursor-pointer"
                  src={CloseDropdown}
                  alt="Close"
                  onClick={() => {
                    toggleDropdown(".main-dropdown");
                  }}
                />
              </div>
              <div className="flex items-center text-white dropdown-item-hover duration-200 p-2 rounded-lg cursor-pointer w-11/12 ml-3">
                <img className="w-4 mr-3" src={NavIcon} alt="Menu" />
                <p className="flex-1">Dashboards</p>
              </div>
              <div className="w-full h-[1px] bg-white opacity-20 mt-3 mb-3"></div>

              <div className="flex flex-col text-white w-11/12 ml-3 mb-3 dropdown-section rounded-lg">
                <div
                  className="flex items-center dropdown-item-hover duration-200 p-2 rounded-lg cursor-pointer section-dropdown-heading"
                  onClick={(e) => {
                    if (
                      artistsData === undefined ||
                      artistsData.data === null
                    ) {
                      e.stopPropagation();
                    } else {
                      toggleScndDropdown(
                        ".section-dropdown-heading",
                        ".dropdown-section",
                        ".dropdown-scnd",
                        ".first-dropdown-arrow"
                      );
                    }
                  }}
                >
                  <p className="flex-1">Artists</p>
                  <a
                    className={`${
                      artistsData === undefined || artistsData.data === null
                        ? "fa-minus"
                        : "fa-angle-down"
                    } fa-solid text-white p-2 rounded-lg h-full items-center flex justify-center w-[35px] ml-auto first-dropdown-arrow dropdown-arrow`}
                    href="#"
                  ></a>
                </div>
                {artistsData === undefined || artistsData.data === null ? (
                  ""
                ) : (
                  <div>
                    <div
                      onScroll={(e) => {
                        checkIfScrollEnd(e);
                      }}
                      className="ml-3 overflow-x-hidden overflow-y-auto h-[180px] duration-300 dropdown-scnd artists-dropdown"
                      style={{
                        maxHeight: 0,
                        transition: "max-height 0.3s ease-in-out",
                      }}
                    >
                      {artistsData.data &&
                        artistsData.data.length > 0 &&
                        artistsData.data.map((a, i) => {
                          return (
                            <a
                              onMouseOver={showDropdown}
                              onMouseOut={hideDropdown}
                              key={i}
                              className="mt-3 block navbar-dropdown-link flex"
                              href="#"
                            >
                              {a.name}{" "}
                              <i className="fa-angle-down fa-solid artist-arrow"></i>
                            </a>
                          );
                        })}
                      <div className="loader4 m-auto my-2"></div>
                    </div>
                  </div>
                )}
                <ul className="artist-aditional-dropdown h-[180px] rounded-lg w-[150px]">
                  <li>
                    <a
                      className="mt-1 block navbar-aditional-dropdown-link"
                      href="#"
                      onMouseOver={showAditionalDropdown}
                      onMouseOut={hideAditionalDropdown}
                    >
                      Leaks
                    </a>
                  </li>
                  <li>
                    <a
                      className="mt-1 block navbar-aditional-dropdown-link"
                      href="#"
                      onMouseOver={showAditionalDropdown}
                      onMouseOut={hideAditionalDropdown}
                    >
                      Snippets
                    </a>
                  </li>
                  <li>
                    <a
                      className="mt-1 block navbar-aditional-dropdown-link"
                      href="#"
                      onMouseOver={showAditionalDropdown}
                      onMouseOut={hideAditionalDropdown}
                    >
                      Database
                    </a>
                  </li>
                  <li>
                    <a
                      className="mt-1 block navbar-aditional-dropdown-link"
                      href="#"
                      onMouseOver={showAditionalDropdown}
                      onMouseOut={hideAditionalDropdown}
                    >
                      Fanpages
                    </a>
                  </li>
                  <li>
                    <a
                      className="mt-1 block navbar-aditional-dropdown-link"
                      href="#"
                      onMouseOver={showAditionalDropdown}
                      onMouseOut={hideAditionalDropdown}
                    >
                      Discord Servers
                    </a>
                  </li>
                </ul>
              </div>

              <div className="flex flex-col text-white w-11/12 ml-3 mb-3 dropdown-section2 rounded-lg">
                <div
                  className="flex items-center dropdown-item-hover duration-200 p-2 rounded-lg cursor-pointer section-dropdown-heading2"
                  onClick={(e) => {
                    toggleScndDropdown(
                      ".section-dropdown-heading2",
                      ".dropdown-section2",
                      ".dropdown-thrd",
                      ".scnd-dropdown-arrow"
                    );
                  }}
                >
                  <p className="flex-1">Producers</p>
                  <a
                    className="fa-solid fa-angle-down text-white p-2 rounded-lg h-full items-center flex justify-center w-[35px] ml-auto scnd-dropdown-arrow"
                    href="#"
                  ></a>
                </div>
                <div
                  className="flex flex-col ml-3 overflow-y-auto h-[180px] duration-300 dropdown-thrd"
                  style={{
                    maxHeight: 0,
                    transition: "max-height 0.3s ease-in-out",
                  }}
                >
                  <a className="mt-3" href="#">
                    Lorem
                  </a>
                  <a className="mt-3" href="#">
                    Ipsum
                  </a>
                  <a className="mt-3" href="#">
                    Dolor
                  </a>
                  <a className="mt-3" href="#">
                    Sit
                  </a>
                  <a className="mt-3" href="#">
                    Amet
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <img
            className="w-[32px] cursor-pointer nav-icons-hover p-2 rounded-lg duration-300"
            src={NavIcon}
            alt="Menu"
          /> */}
          <img
            className="w-[35px] cursor-pointer nav-icons-hover p-2 rounded-lg duration-300"
            src={FolderIcon}
            alt="Menu"
          />
          <a href={fileWebsite}>
            <img
              className="w-[35px] cursor-pointer nav-icons-hover p-2 rounded-lg duration-300 opacity-80 mt-[2px]"
              src={EmailIcon}
              alt="Menu"
            />
          </a>
        </div>
        <NavSearchBar
          updateSearch={props.updateSearch}
          isSearched={props.isSearched}
        ></NavSearchBar>
      </div>
      <div className="right-part nav-bg w-1/6 ml-5 rounded-lg flex items-center justify-evenly p-2 relative">
        <a
          className="rounded-lg w-[35px] text-center text-sm h-full items-center flex justify-center duration-300"
          href="#"
        >
          <img className="rounded-lg" src={user.picture} alt="" />
        </a>
        <p className="text-white text-sm">{user.nickname}</p>
        <Link
          to="/"
          onClick={() => dispatch(setNotifications(true))}
          className="notifications-wrapper relative"
        >
          <i
            className="fa-solid fa-bell absolute"
            style={{ color: "#d9d9d9" }}
          ></i>
          <p className="not-number absolute">
            {notificationsNum !== "" ? notificationsNum : ""}
          </p>
        </Link>
        <div className="w-[32px] h-full mt-0.5">
          <div className="navsearch-bg p-2 rounded-lg h-full items-center flex justify-center w-[35px]">
            <a
              className="fa-solid fa-angle-down text-white main-dropdown-arrow duration-300"
              href="#"
              onClick={() => {
                toggleDropdown(".main-dropdown-scnd");
              }}
            ></a>
          </div>
          <div
            className="absolute top-[48px] right-0 left-0 m-auto dropdown-bg z-20 rounded-b-lg main-dropdown-scnd transition-max-height overflow-hidden"
            style={{
              maxHeight: 0,
              transition: "max-height 0.3s ease-in-out",
            }}
          >
            <div className="flex flex-col text-white w-11/12 ml-3 mb-3 dropdown-section rounded-lg mt-6">
              <Link
                to="/profile"
                className="flex-1 p-2 rounded-xl menu-icon duration-200"
              >
                Profile
              </Link>
            </div>
            <div className="flex flex-col text-white w-11/12 ml-3 dropdown-section rounded-lg mb-6">
              <LogoutButton></LogoutButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
