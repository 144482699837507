import BottomLeft from "./BottomLeft";
import BottomRight from "./BottomRight";

const BottomPart = (props) => {
  return (
    <div className="w-full flex mt-7">
      <BottomLeft
        authToken={props.authToken}
        notifications={props.notifications}
        closeNotifications={props.closeNotifications}
        isSearched={props.isSearched}
        searchStatus={props.searchStatus}
      ></BottomLeft>
      <BottomRight
        authToken={props.authToken}
        isSearched={props.isSearched}
        searchStatus={props.searchStatus}
      ></BottomRight>
    </div>
  );
};

export default BottomPart;
