import React from 'react';
import ReportBtn from "../static/images/report.png";

const ReportButton = (props) => {
    const sendReport = () => {
        const url = process.env.REACT_APP_API_URL + `/song/report`;
        const data = {
            id: props.id,
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // If you're sending a token for authentication purposes, include it here
                //'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Success:', data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <img
            className="w-[13px] h-[13px] inline-block ml-1 report-btn stop-flip"
            src={ReportBtn}
            alt="Report"
            onClick={sendReport}
        />
    );
}

export default ReportButton;
