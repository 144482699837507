import ArtistCard from "./ArtistCard";
import Placeholder from "../static/images/music-placeholder.png";
import Sort1 from "../static/images/sort_1.svg";
import Sort2 from "../static/images/sort_2.svg";
import Sort3 from "../static/images/sort_3.svg";
import Sort4 from "../static/images/sort_4.svg";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setOffset } from "../actions";
import { CSVLink, CSVDownload } from "react-csv";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import NotificationsSection from "./NotificationsSection";

const BottomLeft = (props) => {
  const [data, setData] = useState(undefined);
  const [isFinished, setFinished] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [isChartdata, toggleChartdata] = useState(false);
  const [maxValue, setMaxValue] = useState(0);
  const [chartType, toggleChartType] = useState("");
  const [selectedArtistName, setArtistName] = useState("");
  const limit = 18;
  const searchValue = useSelector((state) => state.search);
  const offset = useSelector((state) => state.offset);
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [DropdownLoader, toggleDropdownLoader] = useState(true);
  const [isExporting, toggleExporting] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [csvData, setCsvData] = useState([
    ["Song Name", "Artist", "Leak Date", "Leaked.cx", "Link"],
  ]);
  const [isDone, setIsDone] = useState(false);
  const csvLinkRef = useRef(null);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  useEffect(() => {
    if (props.authToken === undefined) {
      return;
    }
    if (props.searchStatus === true) {
      dispatch(setOffset(0));
    }

    fetch(
      process.env.REACT_APP_API_URL +
        `/artists?limit=${limit}&offset=${offset}&search=${searchValue}`,
      {
        headers: new Headers({
          Authorization: "Bearer " + props.authToken,
        }),
      }
    )
      .then((response) => response.json())
      .then((fetchedData) => {
        toggleDropdownLoader(false);
        setOffset(0);
        if (props.searchStatus === true) {
          document.querySelector(".artists-container").scrollTop = 0;
        }
        if (fetchedData.data !== undefined || fetchedData.data !== null) {
          if (data === undefined || props.searchStatus === true) {
            setData(fetchedData);
            props.isSearched(false);
            setFinished(false);
            if (notifications === false) {
              document.querySelector(".loader3").style.display = "block";
            }
          } else {
            setData({
              count: fetchedData.count,
              data: [...data.data, ...fetchedData.data],
            });
            if (fetchedData.count - data.data.length <= limit) {
              setFinished(true);
              document.querySelector(".loader3").style.display = "none";
            }
          }
          if (fetchedData.count < 19) {
            document.querySelector(".loader3").style.display = "none";
          }
        }
      });
  }, [props.authToken, offset, searchValue]);

  function getChartData(name, type) {
    toggleDropdownLoader(true);
    fetch(process.env.REACT_APP_API_URL + `/chart?artists=${name}&timeframe=${type}`, {
      headers: new Headers({
        Authorization: "Bearer " + props.authToken,
      }),
    })
      .then((response) => response.json())
      .then((fetchedData) => {
        toggleDropdownLoader(false);
        toggleChartdata(true);
        let transformedData = [];
        // Transform the original data into the desired format
        if (fetchedData.data) {
          transformedData = fetchedData.data.map((item, index) => ({
            date: `${new Date(item.date).getMonth() + 1}/${new Date(
              item.date
            ).getDate()}/${new Date(item.date).getFullYear()}`,
            total: item.total,
            artist: item[fetchedData.artists[0]],
          }));
        }

        // Update the chartData state with the transformed data
        setChartData(transformedData);
      });
  }

  function checkIfScrollEnd(e) {
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
      if (isFinished !== true) {
        if (e.target.classList.contains("select-dropdown")) {
          toggleDropdownLoader(true);
        }

        dispatch(setOffset(limit + offset));
        document.querySelector(".loader3").style.display = "block";
      }
    }
  }

  function setChartType(e) {
    if (e.target.checked) {
      setChartData([]);
      toggleChartdata(false);
      document.querySelectorAll(".chart-checkbox").forEach((ch) => {
        ch.checked = false;
        ch.style = "pointer-events: all";
        ch.closest("label").style = "pointer-events: all";
      });
      e.target.checked = true;
      // Get the nearest label element to the target element
      let label = e.target.closest("label");
      // Set the pointer-events property to "none"
      if (label) {
        label.style.pointerEvents = "none";
        e.target.style.pointerEvents = "none";
      }

      if (e.target.value === "week") {
        toggleChartType("week");
        getChartData(selectedOption, "week");
      } else if (e.target.value === "month") {
        toggleChartType("month");
        getChartData(selectedOption, "month");
      } else if (e.target.value === "year") {
        toggleChartType("year");
        getChartData(selectedOption, "year");
      } else {
        toggleChartType("");
        getChartData(selectedOption, "");
      }
    }
  }

  function exportSelected() {
    document.body.style.cursor = "wait";
    const artists = document.querySelectorAll(".artist-checked");
    setExportData([]);

    // Create an array to store all the fetch promises
    const fetchPromises = [];

    artists.forEach((e) => {
      const fetchPromise = fetch(
        process.env.REACT_APP_API_URL +
          `/posts?limit=1000&offset=0&artist=${e.getAttribute("data-checked")}`,
        {
          headers: new Headers({
            Authorization: "Bearer " + props.authToken,
          }),
        }
      )
        .then((response) => response.json())
        .then((fetchedData) => {
          setExportData((prevExportData) => {
            return [...prevExportData, ...fetchedData.data];
          });
        })
        .catch((error) => {
          console.log("Error fetching data:", error);
        });

      fetchPromises.push(fetchPromise);
    });

    // Use Promise.all to wait for all fetch promises to resolve
    Promise.all(fetchPromises)
      .then(() => {
        setIsDone(true);
        document.body.style.cursor = "default";
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }

  function exportAll() {
    document.body.style.cursor = "wait";
    let allData = []; // Variable to store all the fetched data

    // Create an array to store all the fetch promises
    const fetchPromises = [];

    fetch(process.env.REACT_APP_API_URL + `/artists?limit=40&offset=0`, {
      headers: new Headers({
        Authorization: "Bearer " + props.authToken,
      }),
    })
      .then((response) => response.json())
      .then((fetchedData) => {
        const artistPromises = fetchedData.data.map((e) => {
          return fetch(
            process.env.REACT_APP_API_URL +
              `/posts?limit=1000&offset=0&artist=${e.name}`,
            {
              headers: new Headers({
                Authorization: "Bearer " + props.authToken,
              }),
            }
          )
            .then((response) => response.json())
            .then((fetchedDataScnd) => {
              allData.push(...fetchedDataScnd.data);
            })
            .catch((error) => {
              console.log("Error fetching data:", error);
            });
        });

        return Promise.all(artistPromises);
      })
      .then(() => {
        document.body.style.cursor = "default";
        setExportData(allData); // Set the export data variable
        setIsDone(true);
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  }

  useEffect(() => {
    if (isDone) {
      setCsvData([["Song Name", "Artist", "Leak Date", "Leaked.cx", "Link"]]);
      exportData.forEach((obj) => {
        const { title, artist, created_at, thread, link } = obj;
        setCsvData((prevCsvData) => [
          ...prevCsvData,
          [title, artist, created_at, thread, link],
        ]);
      });
    }
  }, [exportData]);

  return (
    <div className="w-4/6 pt-4 rounded-lg overflow-y-auto scrollbar relative h-full landing-left-part overflow-x-hidden">
      {notifications === false ? (
        <div>
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="flex ml-14 items-center mb-3 pr-14">
            <h5 className="text-white uppercase relative z-10 text-lg">
              Artists
            </h5>
            <div className="h-[22px] ml-7 z-10 flex sort-container items-center">
              <a className="p-1 h-full sort-button flex items-center" href="#">
                <img className="w-[16px] h-[16px]" src={Sort1} alt="Sort" />
              </a>
              <a className="p-1  h-full sort-button flex items-center" href="#">
                <img className="w-[16px] h-[16px]" src={Sort2} alt="Sort" />
              </a>
              <a className="p-1 h-full sort-button flex items-center" href="#">
                <img className="w-[18px] h-[18px]" src={Sort3} alt="Sort" />
              </a>
              <div className="sort-icon flex items-center h-full">
                <img className="w-[16px] h-[16px]" src={Sort4} alt="Sort" />
              </div>
            </div>
            <a
              onClick={() => {
                toggleExporting(!isExporting);
              }}
              className="export-btn ml-auto"
              href="#"
            >
              Export <i className="fa-solid fa-file-csv"></i>
            </a>
            {isExporting ? (
              <div className="export-options">
                <a
                  onClick={() => {
                    exportSelected();
                  }}
                  href="#"
                >
                  Selected
                </a>
                <a
                  onClick={() => {
                    exportAll();
                  }}
                  href="#"
                >
                  All
                </a>
                {csvData.length > 1 ? (
                  <CSVLink
                    filename={"artists.csv"}
                    ref={csvLinkRef}
                    data={csvData}
                  >
                    Download artists
                  </CSVLink>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>

          <div
            onScroll={(e) => {
              checkIfScrollEnd(e);
            }}
            className="flex flex-wrap h-[324px] overflow-y-auto artists-container"
          >
            {data === undefined || props.searchStatus === true ? (
              <span className="loader"></span>
            ) : data.data == null ? (
              <p className="text-white text-center mt-6 w-full no-data-msg chart-message">
                No data <span className="text-red-500">*</span>
              </p>
            ) : (
              data.data &&
              data.data.length > 0 &&
              data.data.map((d, i) => {
                return (
                  <ArtistCard
                    key={i}
                    artistName={d.name}
                    numOfLeaks={"Number of Leaks: " + i}
                    numOfSnippets={"Number of Snippets: " + i}
                    lastLeak={"Last Leak: " + i}
                    artistImage={
                      d.image === "" || d.image === null ? Placeholder : d.image
                    }
                    authToken={props.authToken}
                    metaData={d.metadata}
                    isExporting={isExporting}
                  ></ArtistCard>
                );
              })
            )}
            <div className="w-full">
              <div className="loader3 w-3/3"></div>
            </div>
          </div>
          <div className="w-full h-[1px] bg-white mt-9 mb-7 opacity-10"></div>
          <div
            className="m-2 flex items-center"
            style={{ position: "relative", padding: "20px 10px" }}
          >
            <p className="w-2 h-[270px] text-white leading-4 text-xs font-light text-center">
              N<br></br>u<br></br>m<br></br>b<br></br>e<br></br>r<br></br>
              <br></br>o<br></br>f<br></br>
              <br></br>L<br></br>e<br></br>a<br></br>k<br></br>s
            </p>
            <div className="w-full">
              <div className="flex justify-center chart-type-wrapper">
                <label className="checkbox-container text-white mr-7">
                  <span className="text-xs h-[20px] pt-[3px]">Monthly</span>
                  <input
                    className="chart-checkbox"
                    onChange={(e) => {
                      setChartType(e);
                    }}
                    type="checkbox"
                    value="year"
                    defaultChecked
                    selected
                  ></input>
                  <span className="checkmark"></span>
                </label>
                <label className="checkbox-container text-white mr-7">
                  <span className="text-xs h-[20px] pt-[3px]">Weekly</span>
                  <input
                    className="chart-checkbox"
                    onChange={(e) => {
                      setChartType(e);
                    }}
                    type="checkbox"
                    value="week"
                  ></input>
                  <span className="checkmark"></span>
                </label>
              </div>
              {!isChartdata && selectedOption === "" ? (
                <p className="absolute chart-message">
                  Please select the artist{" "}
                  <span className="text-red-500">*</span>
                </p>
              ) : chartData[0] === undefined && isChartdata ? (
                <p className="absolute chart-message">
                  There are no leaks for this artist{" "}
                  <span className="text-red-500">*</span>
                </p>
              ) : (
                ""
              )}

              <ResponsiveContainer
                className="overflow-hidden"
                width="100%"
                height={340}
                style={
                  chartData[0] === undefined && isChartdata
                    ? { pointerEvents: "none" }
                    : {}
                }
              >
                <AreaChart
                  data={chartData.sort(
                    (a, b) => new Date(a.date) - new Date(b.date)
                  )}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#c12e2f" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#c12e2f" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#730073" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#730073" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey="date"
                    tick={{ fontSize: 11, fill: "#d3d3d3" }}
                    interval={0}
                  />
                  <YAxis
                    domain={[0, maxValue]}
                    tick={{ fontSize: 11, fill: "#d3d3d3" }}
                  />
                  <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.1} />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="total"
                    stroke="#c12e2f" //#11BB8D
                    fill="url(#colorUv)"
                    strokeOpacity={0.4}
                    name="All Leaks"
                  />
                  <Area
                    type="monotone"
                    dataKey="artist"
                    stroke="#730073"
                    fillOpacity={0.4}
                    fill="url(#colorPv)"
                    name={selectedArtistName}
                  />
                  {/* <Legend fontSize={6} /> */}
                </AreaChart>
              </ResponsiveContainer>
              <p className="text-center text-white text-xs font-light dates-placeholder">
                Dates
              </p>
              <ul className="mt-2 overflow-visible w-[1073px] m-auto text-center flex justify-center items-center">
                <li className="text-red-500 inline-block">All Leaks</li>
                <div
                  className={`custom-select mx-4 ${
                    data === undefined ? "disabled" : ""
                  } ${isOpen ? "open" : ""}`}
                >
                  <div
                    className="select-selected"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {selectedOption ? selectedOption : "Select artist"}
                  </div>
                  {isOpen && (
                    <div
                      onScroll={(e) => {
                        checkIfScrollEnd(e);
                      }}
                      className="select-dropdown"
                    >
                      {data !== undefined &&
                        data.data !== null &&
                        data.data.map((d, i) => (
                          <div
                            className="select-option"
                            key={i}
                            onClick={() => {
                              handleOptionSelect(d.name);
                              setArtistName(d.name);
                              getChartData(d.name, chartType);
                            }}
                          >
                            {d.name}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
                {DropdownLoader ? (
                  <li>
                    <div className="custom-loader chart-type-loader"></div>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <NotificationsSection
          closeNotifications={props.closeNotifications}
          authToken={props.authToken}
        ></NotificationsSection>
      )}
    </div>
  );
};

export default BottomLeft;
