import React from "react";
import Navbar from "../components/Navbar";
import { useAuth0 } from "@auth0/auth0-react";

const ProfilePage = () => {
  const { user } = useAuth0();

  return (
    <div className="h-screen w-screen window-bg-img p-8 overflow-y-auto">
      <Navbar></Navbar>
      <div className="h-[833px] w-full profile-container mt-7 p-5">
        <p className="uppercase text-white font-medium">Account</p>
        <img
          className="m-auto rounded-full profile-picture mt-10 w-[100px]"
          src={user.picture}
          alt="Profile picture"
        />
        <div className="w-6/12 inline-block mt-20 profile-section">
          <div className="flex items-center w-[450px] mb-8 m-auto">
            <label className="text-white font-semibold tracking-wider">
              Firstname
            </label>
            <input
              className="ml-auto profile-input text-white text-center p-1 rounded-lg w-[250px] outline-none"
              type="text"
              defaultValue={user.given_name}
            />
          </div>
          <div className="flex items-center w-[450px] mb-8 m-auto">
            <label className="text-white font-semibold tracking-wider">
              Lastname
            </label>
            <input
              className="ml-auto profile-input text-white text-center p-1 rounded-lg outline-none w-[250px]"
              type="text"
              defaultValue={user.family_name}
            />
          </div>
          <div className="flex items-center w-[450px] m-auto">
            <label className="text-white font-semibold tracking-wider">
              Email
            </label>
            <input
              className="ml-auto profile-input text-white text-center p-1 outline-none rounded-lg w-[250px]"
              type="text"
              defaultValue={user.email}
            />
          </div>
        </div>
        <div className="w-6/12 inline-block mt-20 profile-section">
          <div className="flex items-center w-[450px] mb-8 m-auto">
            <label className="text-white font-semibold tracking-wider">
              Phone number
            </label>
            <input
              className="ml-auto profile-input text-white text-center p-1 outline-none rounded-lg w-[250px]"
              type="text"
              defaultValue="+387 123 456"
            />
          </div>
          <div className="flex items-center w-[450px] mb-8 m-auto">
            <label className="text-white font-semibold tracking-wider">
              Discord Hook
            </label>
            <input
              className="ml-auto profile-input text-white text-center p-1 outline-none rounded-lg w-[250px]"
              type="text"
              defaultValue=""
            />
          </div>
          <div className="flex items-center w-[450px] m-auto">
            <label className="text-white font-semibold tracking-wider">
              Telegram bot token
            </label>
            <input
              className="ml-auto profile-input text-white text-center p-1 outline-none rounded-lg w-[250px]"
              type="text"
              defaultValue=""
            />
          </div>
        </div>
        <a
          className="text-center update-btn m-auto px-9 py-1 rounded-lg text-white flex items-center mt-20 "
          href="#"
        >
          Update <i className="fa-solid fa-square-pen ml-3"></i>
        </a>
      </div>
    </div>
  );
};

export default ProfilePage;
